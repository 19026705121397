export const createFontProperties = ({
    family,
    weight,
    size,
    lineHeight = 1.2,
    letterSpacing = "normal",
}) => ({
    fontFamily: `'${family}', sans-serif`,
    fontWeight: weight,
    fontSize: size,
    lineHeight: lineHeight,
    letterSpacing: letterSpacing,
});