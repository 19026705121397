import theme from "../chat/theme";
import { addThemeOverrides } from "@clcdev/gatsby-theme-application/theme";

const textInputStyles = {
    maxWidth: "282px",

    [theme.breakpoints.between(350, 400)]: {
        maxWidth: "320px",
    },
    [theme.breakpoints.between(400, 500)]: {
        maxWidth: "350px",
    },
    [theme.breakpoints.up(500)]: {
        maxWidth: "400px",
    },
};
const styleOverrides = {
    inputsRenderer: {
        container: {
            [theme.breakpoints.up(650)]: {
                alignItems: "flex-start",
            },
        },
        inputWrapper: {
            [theme.breakpoints.down(650)]: {
                width: "auto",
            },
        },
    },
    header: {
        container: {
            [theme.breakpoints.up(350)]: {
                width: "320px",
            },
            [theme.breakpoints.up(399)]: {
                width: "350px",
            },
            [theme.breakpoints.up(499)]: {
                width: "400px",
            },
            [theme.breakpoints.up(650)]: {
                width: "100%",
            },
        },
    },
    dob: {
        container: {
            alignItems: "flex-start",

            [theme.breakpoints.down(350)]: {
                width: "290px",
            },
            [theme.breakpoints.between(350, 400)]: {
                width: "320px",
            },
            [theme.breakpoints.between(400, 500)]: {
                width: "350px",
            },
            [theme.breakpoints.up(500)]: {
                width: "400px",
            },
        },
    },
    submitBlurb: {
        container: {
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
        },
        tosContainer: {
            width: "95%",
            marginTop: "40px",
            marginBottom: "0px",
        },
        tos: {
            fontSize: "11px",
            textAlign: "justify",
            lineHeight: "1.2",
            fontStyle: "normal",
        },
    },
    successBlurb: {
        container: {
            [theme.breakpoints.down(330)]: {
                margin: "15px",
            },
            [theme.breakpoints.down(350)]: {
                margin: "30px",
            },
            [theme.breakpoints.between(350, 390)]: {
                margin: "15% 0% 10% 0%",
            },
            [theme.breakpoints.between(390, 440)]: {
                margin: "35px 0 25px 0",
            },
            [theme.breakpoints.up(440)]: {
                margin: "15% 0% 10% 0%",
            },
            [theme.breakpoints.up(650)]: {
                margin: "0% 0% -7% 20%",
            },
            [theme.breakpoints.up(1200)]: {
                margin: "0% 0% -9% 20%",
            },
        },
    },
    textInput: {
        chat: textInputStyles,
        error: textInputStyles,
    },
};
addThemeOverrides(theme, styleOverrides);

export default theme;

